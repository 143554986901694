import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Input from 'react-phone-number-input/input'
import { isValidPhoneNumber } from "react-phone-number-input"
import Accordion from 'react-bootstrap/Accordion'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from "react-bootstrap/Button"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import ClientFooter from "../utils/ClientFooter"
import { calculateLayover, transformDuration, convertDateFormat, sendConfirmEmail, displayDetailedFlightInformation } from '../utils/utils'
import { baseUrl } from "../utils/Constants"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function ClientInformationPage() {
    const location = useLocation()
    const navigation = useNavigate()

    const { t } = useTranslation()

    const [title, setTitle] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [dob, setDob] = useState("")
    const [gender, setGender] = useState("")
    const [additionalInfo, setAdditionalInfo] = useState("")

    const [organizationName, setOrganizationName] = useState("")
    const [eventName, setEventName] = useState("")
    const [outboundFlight, setOutboundFlight] = useState({ layover_airport: [], market_flight_no: [] })
    const [returnFlight, setReturnFlight] = useState({ layover_airport: [], market_flight_no: [] })
    const [linkId, setLinkId] = useState("")
    const [requestId, setRequestId] = useState("")
    const [passengerId, setPassengerId] = useState("")
    const [amountOwed, setAmountOwed] = useState("")

    const [btnDisabled, setBtnDisabled] = useState(false)
    const [validated, setValidated] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        setOutboundFlight(location.state.outboundFlight)
        setReturnFlight(location.state.returnFlight)

        setFirstName(location.state.firstName)
        setLastName(location.state.lastName)
        setEmail(location.state.email)
        setOrganizationName(location.state.organizationName)
        setEventName(location.state.eventName)
        setLinkId(location.state.id)
        setRequestId(location.state.requestId)
        setPassengerId(location.state.passengerId)

        fetch(baseUrl + "/client/get-amount-owed?" + new URLSearchParams({
            link_id: location.state.id
        }))
            .then(results => results.json())
            .then(data => {
                const owed = location.state.returnFlight.price - (data.max_cost * data.pay_now_percent * 0.01)
                const owedRounded = Math.ceil(owed * 100) / 100
                if (owed < 0) {
                    setAmountOwed(0.0)
                } else {
                    setAmountOwed(owedRounded)
                }
            })
    }, [])

    function handleSubmit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            setBtnDisabled(true)

            // Check phone number validity
            if (!isValidPhoneNumber(phoneNumber)) {
                e.stopPropagation()
                setBtnDisabled(false)
                alert(phoneNumber)
                return
            }

            const passengerData = {
                passenger_id: passengerId,
                title: title,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone_number: phoneNumber,
                dob: dob,
                gender: gender
            }

            const bookingData = {
                outbound_flight: outboundFlight,
                return_flight: returnFlight,
                request_id: requestId,
                link_id: linkId,
                passenger: passengerData
            }

            const additionalInformation = {
                link_id: linkId,
                info: additionalInfo
            }

            fetch(baseUrl + "/client/add-additional-info", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(additionalInformation)
            })

            if (amountOwed > 0) {
                navigation("/booking-pay", { state: { id: location.state.id, booking_data: bookingData, firstName: firstName, lastName: lastName, email: email, eventName: eventName, outboundFlight: outboundFlight, returnFlight: returnFlight } })
            } else {
                fetch(baseUrl + "/client/create-flight-booking", {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bookingData)
                })
                    .then(results => {
                        if (results.status === 400 || results.status === 404) {
                            return navigation('/invalid-expired-link') // TODO: redirect back to start of booking
                        }
                        return results.json()
                    })
                    .then(data => {
                        if (data) {
                            const email_data = {
                                firstName: firstName,
                                lastName: lastName,
                                user_email: email,
                                eventName: eventName,
                                order_pnr: data.order_pnr,
                                outboundFlight: outboundFlight,
                                returnFlight: returnFlight,
                            }
                            sendConfirmEmail(email_data)
                            navigation("/booking-finish", { state: { link_id: location.state.id } })
                        }
                    })
            }
        }
        setValidated(true)
    }

    return (
        <div>
            <Container>
                <Navbar className="navbar-no-bg">
                    <Navbar.Brand>✈️ <b>Planelinks</b> | {t("passenger_information_navbar")}</Navbar.Brand>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <h3>{t("your_selection")}</h3>
                    <Accordion alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div><img style={{ padding: "8px", height: "48px", width: "48px" }} src={outboundFlight.airline_img} alt="Airline Logo" /></div>
                                <div className="individual-flight-option">
                                    <div><b>{convertDateFormat(outboundFlight.departure_time)} - {convertDateFormat(outboundFlight.arrival_time)}</b><br />{outboundFlight.airline_name} · {outboundFlight.market_flight_no.join(", ")}</div>
                                    <div><b>{transformDuration(outboundFlight.duration)}</b><br />{outboundFlight.origin_airport}-{outboundFlight.destination_airport}</div>
                                    <div>{calculateLayover(outboundFlight.layover_airport)}</div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {displayDetailedFlightInformation(outboundFlight)}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div><img style={{ padding: "8px", height: "48px", width: "48px" }} src={returnFlight.airline_img} alt="Airline Logo" /></div>
                                <div className="individual-flight-option">
                                    <div><b>{convertDateFormat(returnFlight.departure_time)} - {convertDateFormat(returnFlight.arrival_time)}</b><br />{returnFlight.airline_name} · {returnFlight.market_flight_no.join(", ")}</div>
                                    <div><b>{transformDuration(returnFlight.duration)}</b><br />{returnFlight.origin_airport}-{returnFlight.destination_airport}</div>
                                    <div>{calculateLayover(returnFlight.layover_airport)}</div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {displayDetailedFlightInformation(returnFlight)}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <p>{t("make_changes_question")} <Link to={"/book-flight?id=" + linkId}>{t("go_back")}</Link></p>
                    <h3>{t("passenger_information")}</h3>
                    <Row>
                        <Col />
                        <Col lg={6} md={9}>
                            <div className="dark-rect-round">
                                <Form noValidate onSubmit={(e) => handleSubmit(e)} validated={validated}>
                                    <Form.Group className='mb-3' controlId='formBasicTitle' onChange={(e) => { setTitle(e.target.value) }}>
                                        <Form.Label>{t("title")}</Form.Label>
                                        <Form.Select as="select" type="select" required>
                                            <option value="">{t("select")}</option>
                                            <option value="Mr">Mr.</option>
                                            <option value="Ms">Ms.</option>
                                            <option value="Mrs">Mrs.</option>
                                            <option value="Dr">Dr.</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{t("error_title")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicFirstName' onChange={(e) => { setFirstName(e.target.value) }}>
                                        <Form.Label>{t("first_name")}</Form.Label>
                                        <Form.Control type="text" placeholder={t("first_name")} defaultValue={firstName} required />
                                        <Form.Control.Feedback type='invalid'>{t("error_first_name")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicLastName' onChange={(e) => { setLastName(e.target.value) }}>
                                        <Form.Label>{t("last_name")}</Form.Label>
                                        <Form.Control type="text" placeholder={t("last_name")} defaultValue={lastName} required />
                                        <Form.Control.Feedback type='invalid'>{t("error_last_name")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicEmail' onChange={(e) => { setEmail(e.target.value) }}>
                                        <Form.Label>{t("email_address")}</Form.Label>
                                        <Form.Control type="email" placeholder={t("email_address")} defaultValue={email} required />
                                        <Form.Control.Feedback type='invalid'>{t("error_email_address")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicPhoneNumber'>
                                        <Form.Label>{t("phone_number")}</Form.Label>
                                        <Input className="form-control" type="tel" placeholder={t("phone_number")} defaultCountry="US" international value={phoneNumber} onChange={setPhoneNumber} required />
                                        <Form.Control.Feedback type='invalid'>{t("error_phone_number")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicGender" onChange={(e) => { setGender(e.target.value) }}>
                                        <Form.Label>{t("gender")}</Form.Label>
                                        <Form.Select as="select" type="select" required>
                                            <option value="">{t("select")}</option>
                                            <option value="M">{t("male")}</option>
                                            <option value="F">{t("female")}</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{t("error_gender")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicDob" onChange={(e) => { setDob(e.target.value) }}>
                                        <Form.Label>{t("date_of_birth")}</Form.Label>
                                        <Form.Control type="date" placeholder={t("date_of_birth")} required />
                                        <Form.Control.Feedback type='invalid'>{t("error_date_of_birth")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicAdditionalInfo' onChange={(e) => { setAdditionalInfo(e.target.value) }}>
                                        <Form.Label>{t("additional_info")}</Form.Label>
                                        <Form.Control type="text" placeholder={t("additional_info")} />
                                    </Form.Group>
                                    <div className="text-align-center">
                                        <Button variant="success" type="submit" className="log-in-button" disabled={btnDisabled}>
                                            {btnDisabled ? t("confirm_button_disabled") : t("confirm_button")}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col />
                    </Row>
                </div>
                <hr />
                <ClientFooter />
            </Container>
        </div>
    )
}

export default ClientInformationPage
